.index-layout-intro
  padding: 204px 16px 32px
  color: white
  background: rgba(#474747, 0.8) center top / cover no-repeat

  @media $bp.mobile
    padding: 96px 8px

  &_premium
    background-image: url('./intro.jpg')

    @media $bp.mobile
      background-image: url('./intro_mob.jpg')

  &_audio
    background-image: url('./intro_audio.jpg')

.index-layout-intro__title
  font-size: 54px
  max-width: 680px
  margin: 0 auto
  font-family: $font-graphik
  line-height: 1.1

  @media $bp.mobile
    $font-size-xx-large()

.index-layout-intro__subtitle
  max-width: 600px
  margin: 24px auto 32px
  font-family: $font-graphik

  @media $bp.mobile
    margin-bottom: 40px
    font-size: 22px

.index-layout-intro__stores
  @media $bp.mobile
    margin-top: 24px

.index-layout-intro__additional-message
  $font-size-x-small()
  margin-top: -24px
  margin-bottom: 24px

.index-layout-intro__additional-message-link
  $font-size-medium()
  $link-underline()
  color: white
  border-color: @color
