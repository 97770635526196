.index-layout-audio
  .index-section__wrapper::before
    content: ''
    position: absolute
    left: 344px
    bottom: 48px
    display: block
    width: 1880px
    height: 94px
    background: url('./earphones.png') center no-repeat
    background-size: contain

    @media $bp.tablet
      left: 128px

    @media $bp.mobile
      display: none

.index-layout-audio__content
  $landing-section-content()

  @media $bp.tablet
    margin-bottom: 88px

  @media $bp.desktop
    margin-bottom: 88px

.index-layout-audio__title
  $landing-section-title()

.index-layout-audio__text
  font-family: $font-kazimir

.index-layout-audio__badge
  $font-size-small()
  display: inline-block
  margin-bottom: 24px
  height: 32px
  padding: 0 14px
  line-height: 32px
  font-weight: bold
  border-radius: 100px
  color: white
  background: $action-audio
