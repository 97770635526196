.index-layout-outro
  padding: 256px 16px 136px
  color: white
  background: rgba(#474747, 0.8) center / cover no-repeat

  @media $bp.mobile
    padding: 104px 8px 120px

  &_premium
    background-image: url('./outro.jpg')

    @media $bp.mobile
      background-image: url('./outro_mob.png')

  &_audio
    background-image: url('./outro_audio.jpg')

.index-layout-outro__title
  font-size: 54px
  max-width: 680px
  margin: 0 auto
  font-family: $font-kazimir
  font-weight: normal
  line-height: 1.1

  @media $bp.mobile
    $font-size-xx-large()

.index-layout-outro__subtitle
  max-width: 600px
  margin: 24px auto 32px
  font-family: $font-kazimir

  @media $bp.mobile
    margin-bottom: 40px
