.index-layout
  font-size: 18px
  color: white
  text-align: center

  @media $bp.desktop, $bp.desktopMedium, $bp.desktopLarge
    font-size: 22px

.index-layout__button
  display: inline-block
  margin-bottom: 48px
  font-family: $font-graphik

  @media $bp.mobile
    margin-bottom: 0

.index-layout__link
  $btn-primary()
  $btn-huge()
