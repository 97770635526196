.index-layout-showcase
  .index-section
    padding: 64px 0

    @media $bp.desktop, $bp.desktopMedium, $bp.desktopLarge
      padding: 64px 16px

.index-layout-showcase__title
  $landing-section-title()
  margin-left: 0
  text-align: left

  @media $bp.oneColumn
    margin-left: 16px

.index-layout-showcase__books
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  text-align: left

  .book_grid, .audiobook_grid, .comicbook_grid

    &:not(:last-child)
      margin-right: 24px
      margin-bottom: 40px

    &:nth-child(6n)
      margin-right: 0

.index-layout-showcase

  .horizontal-scroller

    .book_grid, .audiobook_grid, .comicbook_grid
      &:first-child
        margin-left: 8px

      &:not(:last-child)
        margin-right: 24px
